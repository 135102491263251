<template>
  <tabs
    class="marketing-tabs"
    :active-tab="activeTabName"
    :tabs="tabOptions"
    custom-tabs
    @select-tab="selectTab"
  >
    <div slot="custom-tabs" slot-scope="tab" class="custom-tab flex--align-center">
      <span class="mr10 inline-block custom-icon" :class="getTemplateIconClass(tab.name)"></span>
      <span
        class="custom-tab__label"
        :class="{ 'custom-tab__label--active': activeTabName === tab.name }"
      >
        {{ tab.label }}
      </span>
    </div>
  </tabs>
</template>

<script>
import Tabs from '@/modules/dashboard/components/common/Tabs';

import { getMessageTemplateTypeIconClass } from '@/modules/dashboard/utils';

export default {
  name: 'MarketingCampaignTabs',
  components: { Tabs },
  props: {
    activeTabName: {
      type: String,
      required: true
    },
    tabOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectTab(tab) {
      this.$emit('select-tab', tab);
    },
    getTemplateIconClass(type) {
      const isActive = type === this.activeTabName;

      return getMessageTemplateTypeIconClass(type, isActive);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.marketing-tabs {
  margin-top: 46px;
  background-color: $white;

  /deep/ .tabs {
    width: 100%;

    &__container {
      height: 30px;
      margin: 20px 0 10px;
    }
  }
}

.custom-tab {
  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: $text-color;

    &__active {
      color: $main-title-color;
    }
  }
}
</style>
