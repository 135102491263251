<template>
  <div class="template__container flex-column">
    <mobile-header
      class="mobile-header__container"
      :should-redirect="false"
      @return-back="onBackButtonClick"
    >
      <div slot="header-title" class="template__mobile-header ellipsized flex--align-center">
        {{ marketingCampaignTitleLabel }}
      </div>
    </mobile-header>

    <marketing-campaign-template-tabs class="onlyXS" @select-tab="changeActiveMessageTemplate" />

    <div class="template__content">
      <div v-if="isSkinxsCampaign" class="template__description">
        <i>{{ templateDescriptionText }}</i>
      </div>
      <div class="template__header flex--space-between--center">
        <div
          class="header__title flex-column mb15"
          :class="{ 'header__title--skinxs': isSkinxsCampaign }"
        >
          <div class="template__label">{{ $t('dashboard.label.marketingCampaignTitle') }}</div>
          <input
            :value="marketingCampaignTitleLabel"
            type="text"
            class="rounded-input template__input input-focus-within"
            :class="{ 'error-highlight': !isSkinxsCampaign && isTemplateTitleError }"
            :disabled="isSkinxsCampaign"
            @input="updateMarketingCampaignTitle"
          />
        </div>
        <div class="header__language-select mb15">
          <language-dropdown-input :language="language" @language-select="changeLanguage" />
        </div>
      </div>
      <div class="divider otherXS" />
      <div v-if="isEmailMessageTemplate" class="template__subject mb15">
        <div class="template__label">{{ $t('dashboard.label.subject') }}</div>
        <input
          v-validate.disable="'required'"
          :value="templateData.subject"
          name="subject"
          type="text"
          class="rounded-input template__input input-focus-within"
          :class="{ 'error-input-border': templateErrors.subject }"
          @input="onSubjectInput"
          @change="updateCurrentTemplateField({ fieldName: 'subject', value: $event.target.value })"
        />
      </div>

      <component
        :is="templateComponentName"
        class="mb20"
        :template-data="templateData"
        :template-errors="templateErrors"
        :fields-max-length="fieldsMaxLength"
        :template-type="activeTemplateType"
        @field-input="onMessageInput"
        @field-change="updateCurrentTemplateField"
      />

      <div v-if="isSmsMessageTemplate" class="template__counter mb20">
        <i class="counter__text">
          {{ $t('dashboard.label.charactersLeft', [remainingCharacters]) }},
          {{ $t('dashboard.action.smsCounter', [smsCount, 5]) }}
        </i>
      </div>
      <div class="template__footer flex--space-between--center">
        <button class="rounded-button-white" @click="updateCampaignTemplate">
          {{ $t('dashboard.action.updateTemplate') }}
        </button>
        <div v-if="isSkinxsCampaign">
          <div class="rounded-button-gray" @click="resetTextToDefault">
            {{ $t('dashboard.action.resetTextToDeafault') }}
          </div>
        </div>
        <div v-else @click="openDeleteModal">
          <span class="far fa-trash-alt pointer" />
          <a class="footer__delete-button">
            {{ $t('dashboard.action.deleteCampaign') }}
          </a>
        </div>
      </div>
    </div>

    <delete-custom-campaign-modal @delete-campaign="deleteCampaign" />
    <unsaved-changes-modal />
    <dashboard-notification />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Splitter from 'split-sms';
import { get } from 'lodash';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import LanguageDropdownInput from '@/modules/dashboard/components/profile/LanguageDropdownInput';
import DeleteCustomCampaignModal from '@/modules/dashboard/components/message-templates/DeleteCustomCampaignModal';
import DashboardNotification from '@/modules/dashboard/components/dashboard/common/DashboardNotification';
import UnsavedChangesModal from '@/modules/dashboard/components/message-templates/UnsavedChangesModal';
import MarketingCampaignTemplateTabs from '@/modules/dashboard/components/message-templates/MarketingCampaignTemplateTabs';
import DefaultMarketingMessageTemplate from '@/modules/dashboard/components/message-templates/marketing/DefaultMarketingMessageTemplate';
import EmailMarketingMessageTemplate from '@/modules/dashboard/components/message-templates/marketing/EmailMarketingMessageTemplate';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';
import saveMarketingTemplateMixin from '@/modules/dashboard/components/mixins/saveTemplateMixin';
import updateTemplateFieldMixin from '@/modules/dashboard/components/mixins/updateTemplateFieldMixin';

import {
  getMarketingCampaignTemplate,
  deleteMessageTemplate,
  updateTemplateGroup
} from '@/modules/dashboard/api';

import {
  isMobileDevice,
  getStringLength,
  getNonNegativeNumber,
  buildMessageTemplateText,
  getTemplateErrorsFromVeeValidationErrors,
  hasTemplateError
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import {
  MESSAGE_TEMPLATE_TYPES,
  MESSAGE_TEMPLATES_TAB_NAMES,
  MESSAGE_MAX_LENGTH,
  SMS_MAX_LENGTH,
  TEMPLATE_TYPE_NAME,
  SMS_TEMPLATE_CONST_SYMBOLS,
  TEMPLATE_GROUP_TYPES,
  MARKETING_CAMPAIGN_TEMPLATE_NAMES,
  MARKETING_CAMPAIGN_TITLES_KEY
} from '@/modules/dashboard/api/constants';

const TEMPLATE_COUNTABLE_FIELDS = {
  greeting: '',
  bodyPartOne: '',
  bodyPartTwo: '',
  bodyPartThree: '',
  signature: ''
};

const CURLY_BRACES_COUNTER_VALUE = 2;

export default {
  name: 'MarketingCampaignTemplate',
  components: {
    EmailMarketingMessageTemplate,
    DefaultMarketingMessageTemplate,
    MarketingCampaignTemplateTabs,
    MobileHeader,
    LanguageDropdownInput,
    DeleteCustomCampaignModal,
    DashboardNotification,
    UnsavedChangesModal
  },
  mixins: [templateUnsavedChangesMixin, saveMarketingTemplateMixin, updateTemplateFieldMixin],
  props: {
    messageTemplateType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      language: null,
      templateData: {
        title: '',
        subject: '',
        ...TEMPLATE_COUNTABLE_FIELDS
      },
      lengthData: {
        ...TEMPLATE_COUNTABLE_FIELDS
      },
      isTemplateTitleError: false,
      activeTemplateType: MESSAGE_TEMPLATE_TYPES.EMAIL,
      marketingCampaignTitle: '',
      templateErrors: {}
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      userLocale: rootTypes.getters.GET_USER_LANGUAGE,
      campaigns: types.getters.MARKETING_CAMPAIGNS,
      activeCampaign: types.getters.ACTIVE_MARKETING_CAMPAIGN,
      savedMessageTemplate: types.getters.SAVED_MESSAGE_TEMPLATE,
      currentMessageTemplate: types.getters.CURRENT_MESSAGE_TEMPLATE
    }),
    marketingCampaignId() {
      return get(this.activeCampaign, 'id');
    },
    isSkinxsCampaign() {
      return get(this.activeCampaign, 'type') === TEMPLATE_GROUP_TYPES.SKINXS_CAMPAIGN;
    },
    isEmailMessageTemplate() {
      return this.activeTemplateType === MESSAGE_TEMPLATE_TYPES.EMAIL;
    },
    isFacebookMessageTemplate() {
      return this.activeTemplateType === MESSAGE_TEMPLATE_TYPES.FACEBOOK;
    },
    isSmsMessageTemplate() {
      return this.activeTemplateType === MESSAGE_TEMPLATE_TYPES.SMS;
    },
    templateComponentName() {
      return this.isEmailMessageTemplate
        ? MARKETING_CAMPAIGN_TEMPLATE_NAMES.EMAIL_TEMPLATE
        : MARKETING_CAMPAIGN_TEMPLATE_NAMES.DEFAULT;
    },
    templateDescriptionText() {
      const templateTypeName = TEMPLATE_TYPE_NAME[this.activeTemplateType];

      return this.isFacebookMessageTemplate
        ? this.$t('dashboard.label.questionnaireInvitationfacebook')
        : this.$t('dashboard.label.questionnaireInvitationMail', [templateTypeName]);
    },
    templateMessageLinkName() {
      return get(this.savedMessageTemplate, 'linkName') || '';
    },
    footerAndConstSymbolsLength() {
      return this.templateMessageLinkName.length + SMS_TEMPLATE_CONST_SYMBOLS;
    },
    editableTextMaxLength() {
      return this.smsMaxLength - this.footerAndConstSymbolsLength;
    },
    greetingMaxLength() {
      return this.getFieldLength(this.greetingLength + this.messageSymbolsLeft);
    },
    bodyPartOneMaxLength() {
      return this.getFieldLength(this.bodyPartOneLength + this.messageSymbolsLeft);
    },
    bodyPartTwoMaxLength() {
      return this.getFieldLength(this.bodyPartTwoLength + this.messageSymbolsLeft);
    },
    bodyPartThreeMaxLength() {
      return this.getFieldLength(this.bodyPartThreeLength + this.messageSymbolsLeft);
    },
    signatureMaxLength() {
      return this.getFieldLength(this.signatureLength + this.messageSymbolsLeft);
    },
    fieldsMaxLength() {
      return {
        greeting: this.greetingMaxLength,
        bodyPartOne: this.bodyPartOneMaxLength,
        bodyPartTwo: this.bodyPartTwoMaxLength,
        bodyPartThree: this.bodyPartThreeMaxLength,
        signature: this.signatureMaxLength
      };
    },
    greetingLength() {
      return getStringLength(this.lengthData.greeting);
    },
    bodyPartOneLength() {
      return getStringLength(this.lengthData.bodyPartOne);
    },
    bodyPartTwoLength() {
      return getStringLength(this.lengthData.bodyPartTwo);
    },
    bodyPartThreeLength() {
      return getStringLength(this.lengthData.bodyPartThree);
    },
    signatureLength() {
      return getStringLength(this.lengthData.signature);
    },
    messageSymbolsLength() {
      return (
        this.greetingLength +
        this.bodyPartOneLength +
        this.bodyPartTwoLength +
        this.bodyPartThreeLength +
        this.signatureLength +
        CURLY_BRACES_COUNTER_VALUE
      );
    },
    messageSymbolsLeft() {
      return getNonNegativeNumber(this.editableTextMaxLength - this.messageSymbolsLength);
    },
    smsMaxLength() {
      return SMS_MAX_LENGTH[this.marketingCampaignSmsCounter.characterSet];
    },
    marketingCampaignText() {
      return this.buildTemplateTextWithField();
    },
    marketingCampaignSmsCounter() {
      return Splitter.split(this.marketingCampaignText);
    },
    remainingCharacters() {
      return this.marketingCampaignSmsCounter.remainingInPart;
    },
    smsCount() {
      const { parts } = this.marketingCampaignSmsCounter;

      return parts && parts.length;
    },
    marketingCampaignTitleLabel() {
      return this.isSkinxsCampaign
        ? this.$t(MARKETING_CAMPAIGN_TITLES_KEY[this.marketingCampaignTitle])
        : this.marketingCampaignTitle;
    }
  },
  watch: {
    savedMessageTemplate(template) {
      const {
        title,
        subject,
        greeting,
        bodyPartOne,
        bodyPartTwo,
        bodyPartThree,
        linkName,
        signature,
        bestSkinLabel,
        yourDoctorLabel
      } = template;

      const lengthFields = {
        greeting,
        bodyPartOne,
        bodyPartTwo,
        bodyPartThree,
        signature
      };

      this.templateData = {
        ...this.templateData,
        title,
        subject,
        linkName,
        bestSkinLabel,
        yourDoctorLabel,
        ...lengthFields
      };

      this.lengthData = { ...lengthFields };
    }
  },
  async created() {
    this.language = this.userLocale;
    this.activeTemplateType = this.messageTemplateType || MESSAGE_TEMPLATE_TYPES.EMAIL;
    this.marketingCampaignTitle = get(this.activeCampaign, 'title', '');

    await this.getMarketingTemplate();

    if (isMobileDevice()) {
      const tabName = this.isSkinxsCampaign
        ? MESSAGE_TEMPLATES_TAB_NAMES.SKINXS_CAMPAIGNS
        : MESSAGE_TEMPLATES_TAB_NAMES.CUSTOM_CAMPAIGNS;

      await this.setActiveMessageTemplateTab(tabName);
    }
  },
  async destroyed() {
    await this.updateStoreMessageTemplate({});
  },
  async beforeRouteLeave(to, from, next) {
    if (!isMobileDevice()) {
      next();

      return;
    }

    const canContinue = await this.onTemplateClose();

    if (!canContinue) {
      next(false);

      return;
    }

    next();
  },
  methods: {
    ...mapActions({
      setActiveMessageTemplateTab: types.actions.SET_ACTIVE_MESSAGE_TEMPLATE_TAB,
      deleteMarketingCampaign: types.actions.DELETE_MARKETING_CAMPAIGN,
      setActiveMarketingCampaign: types.actions.SET_ACTIVE_MARKETING_CAMPAIGN,
      setCurrentMessageTemplate: types.actions.SET_CURRENT_MESSAGE_TEMPLATE
    }),
    getFieldLength(smsLength) {
      return this.isSmsMessageTemplate ? getNonNegativeNumber(smsLength) : MESSAGE_MAX_LENGTH;
    },
    buildTemplateTextWithField({ fieldName, fieldValue } = {}) {
      const textObject = {
        ...this.lengthData,
        linkName: this.templateMessageLinkName
      };

      if (fieldName) {
        textObject[fieldName] = fieldValue;
      }

      return buildMessageTemplateText(textObject);
    },
    onSubjectInput(event) {
      this.templateErrors.subject = false;

      if (!isMobileDevice()) {
        return;
      }

      this.updateCurrentTemplateField({ fieldName: 'subject', value: event.target.value });
    },
    onMessageInput(params) {
      this.updateTemplateField(params);
      this.templateErrors[params.fieldName] = false;

      if (isMobileDevice()) {
        const { fieldName, event } = params;

        this.updateCurrentTemplateField({ fieldName, value: event.target.value });
      }
    },
    updateCurrentTemplateField({ fieldName, value }) {
      this.templateData[fieldName] = value;

      this.setCurrentMessageTemplate({ ...this.currentMessageTemplate, [fieldName]: value });
    },
    async changeActiveMessageTemplate(type) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.activeTemplateType = type;
      await this.getMarketingTemplate();
    },
    async getMarketingTemplate() {
      if (!this.userId || !this.marketingCampaignId || !this.language) {
        return;
      }

      const { data: marketingTemplate } = await getMarketingCampaignTemplate({
        userId: this.userId,
        groupId: this.marketingCampaignId,
        wayOfSend: this.activeTemplateType,
        language: this.language
      });

      await this.updateStoreMessageTemplate(marketingTemplate);
    },
    async changeLanguage(language) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.language = language;
      await this.getMarketingTemplate();
    },
    updateMarketingCampaignTitle({ target: { value: title } }) {
      if (this.isSkinxsCampaign) {
        return;
      }

      this.marketingCampaignTitle = title;
    },
    async updateTitleForTemplateGroup() {
      const hasSameTitle = this.marketingCampaignTitle === get(this.activeCampaign, 'title');

      if (hasSameTitle || this.isSkinxsCampaign) {
        return;
      }

      const templateGroup = { ...this.activeCampaign, title: this.marketingCampaignTitle };

      await updateTemplateGroup({ userId: this.userId, templateGroup });
      await this.setActiveMarketingCampaign(templateGroup);
    },
    async updateCampaignTemplate() {
      if (this.marketingCampaignTitle === '') {
        this.isTemplateTitleError = true;

        return;
      }

      await this.$validator.validateAll();

      this.templateErrors = getTemplateErrorsFromVeeValidationErrors(this.errors);

      if (hasTemplateError(this.templateErrors)) {
        return;
      }

      this.updateTitleForTemplateGroup();
      this.updateTemplate();
    },
    openDeleteModal() {
      this.$modal.show('delete-custom-campaign-modal');
    },
    async deleteCampaign() {
      await this.deleteMarketingCampaign({
        userId: this.userId,
        marketingCampaignId: this.marketingCampaignId
      });

      if (isMobileDevice()) {
        this.$router.push({ name: 'MessageTemplates' });
      }
    },
    async resetTextToDefault() {
      const { id: templateId } = this.savedMessageTemplate;

      if (templateId) {
        await deleteMessageTemplate({
          userId: this.userId,
          wayOfSend: this.activeTemplateType,
          templateId
        });
      }

      await this.getMarketingTemplate();

      this.templateErrors = {};
    },
    async onBackButtonClick() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/css/common/errors';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.template {
  &__container {
    padding: 30px;
  }

  &__mobile-header {
    font-size: 13px;
    color: $main-title-color;
  }

  &__description {
    margin-bottom: 15px;

    font-size: 12px;
    color: $grey-color;
  }

  &__label {
    margin-bottom: 10px;

    font-size: 13px;
    font-weight: 300;
    color: $text-color;
  }

  &__input {
    width: 100%;

    margin-bottom: 0;

    &:disabled {
      font-size: 15px;

      color: $grey-color;
      background-color: $background;
    }
  }

  &__footer {
    font-size: 15px;
  }
}

.header {
  &__title {
    width: 67%;
  }

  &__language-select {
    width: 30%;
    font-size: 13px;
    color: $text-color;
  }
}

.message {
  &__input {
    padding: 5px 10px;

    font-size: 15px;

    border: 1px solid $light-blue;
    border-radius: 5px;
  }

  &__textarea {
    border: 0;

    &:focus {
      outline: none;
    }
  }

  &__footer {
    color: $grey-color;
  }
}

.counter {
  &__text {
    font-size: 12px;
    color: $grey-color;
  }
}

/deep/ .language-label {
  font-size: 13px;
  color: $text-color;
}

.divider {
  margin: 5px 0 15px;
}

.fa-trash-alt {
  color: $icon-grey-color;
}

@media (max-width: 767px) {
  .template {
    &__container {
      padding: 0;
    }

    &__mobile-header {
      font-size: 16px;
      line-height: 19px;
    }

    &__content {
      margin-top: 46px;
      min-height: calc(100vh - 46px);
      padding: 15px 15px 30px;
      background-color: $white;
    }

    &__description {
      margin-bottom: 25px;

      font-size: 10px;
    }

    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    &__input,
    &__subject,
    &__signature {
      font-size: 16px;
      line-height: 19px;

      &:disabled {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &__signature {
      margin-bottom: 20px;
    }

    &__footer {
      height: 80px;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;
    }
  }

  .message {
    &__footer {
      word-break: break-word;
    }
  }

  .marketing-tabs + .template__content {
    margin-top: 0;
    min-height: calc(100vh - 104px);
  }

  .message__input,
  .footer__delete-button {
    font-size: 16px;
    line-height: 19px;
  }

  .template__container .mobile-header {
    &__container {
      margin-bottom: 0;
    }
  }

  .header {
    &__title,
    &__language-select {
      width: 100%;
    }

    &__language-select {
      font-size: 12px;
    }

    &__title {
      &--skinxs {
        display: none;
      }
    }
  }

  .rounded-button-white,
  .rounded-button-gray {
    padding: 6px 15px;

    font-size: 16px;
    line-height: 19px;
  }
}
</style>
