<template>
  <modal
    name="delete-custom-campaign-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <p class="title">{{ $t('dashboard.label.deleteCampaign') }}</p>
        <div class="buttons-group">
          <a class="cancel-button" @click="onCancel">
            {{ $t('dashboard.action.cancel') }}
          </a>
          <button class="rounded-button-white" @click="onDelete">
            {{ $t('dashboard.action.yesDelete') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'DeleteCustomCampaignModal',
  methods: {
    onCancel() {
      this.hideModal();
    },
    onDelete() {
      this.$emit('delete-campaign');

      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('delete-custom-campaign-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.modal-window-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 210px;
  box-sizing: border-box;
  padding: 20px;

  background-color: #ffffff;
  font-family: var(--u-font-family-Gilroy);
  border-radius: 8px;

  font-size: 15px;
  line-height: 17px;
}

.title {
  margin-top: 40px;
  margin-bottom: 40px;

  color: #324752;
}

.cancel-button {
  text-decoration: none;

  color: #8da4b0;
}

.buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 767px) {
  .modal-window-content,
  .cancel-button {
    font-size: 13px;
  }
}
</style>
