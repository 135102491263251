<template>
  <marketing-campaign-tabs
    :active-tab-name="activeTabName"
    :tab-options="$options.marketingCampaignsTabs"
    @select-tab="selectTab"
  />
</template>

<script>
import MarketingCampaignTabs from '@/modules/dashboard/components/message-templates/MarketingCampaignTabs';
import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import {
  TEMPLATE_TYPE_NAME,
  MESSAGE_TEMPLATE_TYPE_VALUES,
  MESSAGE_TEMPLATE_TYPES
} from '@/modules/dashboard/api/constants';

const MARKETING_TEMPLATE_TABS = MESSAGE_TEMPLATE_TYPE_VALUES.map(
  type => new TabInfo(type, TEMPLATE_TYPE_NAME[type])
);

export default {
  name: 'MarketingCampaignTemplateTabs',
  components: { MarketingCampaignTabs },
  marketingCampaignsTabs: MARKETING_TEMPLATE_TABS,
  data() {
    return {
      templateTypes: TEMPLATE_TYPE_NAME,
      activeTabName: null,
      activeCampaignTitle: null
    };
  },
  created() {
    this.activeTabName = MESSAGE_TEMPLATE_TYPES.EMAIL;
  },
  methods: {
    selectTab(templateType) {
      this.activeTabName = templateType;
      this.$emit('select-tab', templateType);
    }
  }
};
</script>
